import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import './post.scss'
  
// Sections
import LazySizesCenter from '../components/image/lazysizesCenter'
import TransitionLink from 'gatsby-plugin-transition-link'
import { exitTransition, entryTransition} from '../components/transition/transition'
import shakeString from '../helpers/shakeString'
import Transition from '../components/transition/transition'
import Info from '../components/sections/header'
import Slideshow from '../components/sections/slideshowProportional'
import Images from '../components/sections/images'
import Video from '../components/sections/video'

const params = {
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  spaceBetween: 100,
  slidesPerView: "auto",
  centeredSlides: true,
  loop: true,
  speed: 600,
  lazy: true,
  preloadImages: false,
  // Enable when slides perview is auto
  watchSlidesVisibility: true,
  freeMode: true,
  breakpoints: {
      1200: {
          freeMode: false,
      },
  },
}

const Hero = ({media}) => {
  
  if (media.__typename === "WPGraphQL_Post_Project_Hero_Image" || media.__typename === "WPGraphQL_Post_Project_Preview_Image"){
    if(!media.image) return false
    return (
      <LazySizesCenter 
      image={media.image} 
      preload={true}/>
    )

  }else if(media.__typename === "WPGraphQL_Post_Project_Hero_Video" || media.__typename === "WPGraphQL_Post_Project_Preview_Video"){
    return(

      <div className="video-header"><video src={media.video.mediaItemUrl} autoPlay={true} loop={true} muted/></div>
    )
  }else{
    console.log("no hero image/video")
  }
}

const Section = ({type, section, params}) => {

  switch (section.__typename) {
    case "WPGraphQL_Post_Project_Sections_Gallery":
      return <Slideshow className={`section section__${section.navigation}`} params={params} images={section.images} pattern={true}/>
      // return (
      //   <Suspense fallback={<Loader />}>
      //     <Slideshow className={`section section__${section.navigation}`} params={params} images={section.images} pattern={true}/>
      //   </Suspense>
      // )
    case "WPGraphQL_Post_Project_Sections_ImagesLayout":
      return <Images className={`section section__${section.navigation}`} images={section.images} />
      // return (
      //   <Suspense fallback={<Loader />}>
      //     <Images className={`section section__${section.navigation}`} images={section.images} />
      //   </Suspense>
      // )
    case "WPGraphQL_Post_Project_Sections_Video":
      return <Video className={`section section__${section.navigation}`} video={section.video} />
      // return (
      //   <Suspense fallback={<Loader />}>
      //     <Video className={`section section__${section.navigation}`} video={section.video} />
      //   </Suspense>
      // )
    default:
      return false
  }
}

export default ({ data }) => {
    // console.log(data)
    const title = data.wpgraphql.post.title
    const { list, index, sections, preview, hero, relationship } = data.wpgraphql.post.project
    // const relationshipArray = relationship ? data.wpgraphql.post.project.relationship.split('/') : [];
    const relationshipPath = relationship ? `/${data.wpgraphql.post.project.relationship.slug}` : false
    const nextPath = `/${data.wpgraphql.posts.nodes[Math.floor(Math.random() * data.wpgraphql.posts.nodes.length)].slug}`

    const [ state ] = useState({
      title: shakeString(title)
    })

    const handleScroll = (e) => {
      const dataSection = e.currentTarget.getAttribute('data-section')
      const element = document.querySelector(`.section__${dataSection}`)
      if(element) element.scrollIntoView({block: "start", behavior: "smooth"})
    }
    // console.log(hero)

    const hero_post  = hero[0] || preview[0]


    useEffect(() => {
      // Make sure, page starts at the top!
      const main = document.querySelector('main')
      if(main) main.scrollTop = 0

    }, [])

    return (
      <Transition>
        <div className="page__post">
          {/* <SEO title={title} /> */}
          {/* <div className="post__background">
            <Pattern color={"black"} width="1200" height="4000" position="absolute" scale={0.5}/>
          </div> */}

          <div className="section__top">

            {hero_post ? <Hero media={hero_post}/> : false}
        
          </div> 
          {/* <Suspense fallback={<Loader />}> */}
            <Info title={state.title} index={index} list={list} sections={sections}/>
          {/* </Suspense> */}

          <div className="headerSticky">
            <div>
              <span>
                <span dangerouslySetInnerHTML={{__html: title}} data-section="top" onClick={handleScroll} onKeyDown={handleScroll} role="button" tabIndex={0} aria-label="ScrollTo" className="link" />
              
                {relationship ? <TransitionLink 
                  to={relationshipPath}
                  exit={exitTransition}
                  entry={entryTransition}
                  dangerouslySetInnerHTML={{ __html: " • Related Project" }}
                /> 
                :false }
              </span>
            </div>
            <div>
              <TransitionLink 
                to={nextPath}
                exit={exitTransition}
                entry={entryTransition}
                dangerouslySetInnerHTML={{ __html: "Next" }}
              /> 
            </div>
          </div> 

          
          {
            sections ? sections.map((section, i) => (
                <Section key={i} params={params} section={section} />
            )) : false
          }
      </div>
    </Transition>
)}


export const pageQuery = graphql`
  query currentPost($id: ID!) {
    wpgraphql{
      posts(first: 200) {
        nodes{
          slug
        }
      }
      post(id: $id ) {
        title(format: RENDERED)
        slug
        project {
          list {
            label
            text
          }
          preview {
            __typename
            ... on WPGraphQL_Post_Project_Preview_Image {
              # ...Image
              image{
                altText
                mediaItemUrl
                mediaDetails {
                  sizes{
                    sourceUrl
                    width
                    height
                  }
                }
              }
            }
            ... on WPGraphQL_Post_Project_Preview_Video {
              video {
                mediaItemUrl
              }
            }
          }
          
          hero {
            __typename
            ... on WPGraphQL_Post_Project_Hero_Image {
              image{
                altText
                mediaItemUrl
                mediaDetails {
                  sizes{
                    sourceUrl
                    width
                    height
                  }
                }
              }
            }
            ... on WPGraphQL_Post_Project_Hero_Video {
              video {
                mediaItemUrl
              }
              image{
                altText
                mediaItemUrl
                mediaDetails {
                  sizes{
                    sourceUrl
                    width
                    height
                  }
                }
              }
            }
          }
          
  
          sections {
            __typename
            ... on WPGraphQL_Post_Project_Sections_Gallery {
              
              ...Images
              navigation
            }
            ... on WPGraphQL_Post_Project_Sections_Text {
              navigation
              text
            }
            ... on WPGraphQL_Post_Project_Sections_ImagesLayout {
              navigation
              images{
                altText
                mediaItemUrl
                mediaDetails {
                  sizes{
                    sourceUrl
                    width
                    height
                  }
                }
              }
            }
            ... on WPGraphQL_Post_Project_Sections_Video {
              navigation
              video{
                mediaItemUrl
              }
            }
          }
          relationship {
            ... on WPGraphQL_Post {
              slug
              title
            }
          }
        }
      }
      categories {
        nodes {
          slug
        }
      }
    }
  }
`


// credits{
//   label
//   text
// }

// fluid(maxWidth: 1920){
//   ...GatsbyImageSharpFluid_withWebp
// }


// Gallery
// fluid(maxWidth: 1920){
//   ...GatsbyImageSharpFluid_withWebp
// }

// Layout
// fluid(maxWidth: 1920){
//   ...GatsbyImageSharpFluid_withWebp
// }