const shakeString = (string) => {  
    
    // if(typeof string !== "string") return ""
    if(string.match(/<span>/)) return string
    
    let isBreak = false;
  
    const infotextArray = string.split('').map((letter, i) => {
  
        // Check for breaks!!
        if(letter === "<"){
            isBreak = true
        }
    
        //   if(isBreak || letter === " ") return "letter"
        if(isBreak){
            if(letter === ">"){
                isBreak = false
            }
            return letter
        }
        if(letter === " ") return letter
    
        if(Math.random() < 0.7){
            return letter
        }else{
            return `<span>${letter}</span>`
        }
        
    })

  
    const infotextConverted = infotextArray.join('')
    // console.log(infotextConverted)
  
    return infotextConverted
  
}

export default shakeString