import React, { useState, useEffect } from "react"
import LazySizes from '../image/lazysizes'

import Swiper from 'react-id-swiper';

// import TransitionLink from 'gatsby-plugin-transition-link'
// import Img from "gatsby-image"

import 'swiper/swiper.scss'
import './slideshow.scss'

const Slideshow = ({ images, background, params }) => {

    const [ mySwiper, setMySwiper ] = useState(null)
    const [ vh, setVh ] = useState(typeof window !== 'undefined' ? window.innerHeight * 0.01 : 100)

    const initSwiper = (newSwiper) => {
        setMySwiper(newSwiper)
   
    }

    useEffect(() => {
        if (mySwiper !== null) {
            mySwiper.on("resize", () => {
                if(typeof window !== 'undefined'){
                    mySwiper.params.centeredSlides = window.innerWidth < 900;
                    mySwiper.update();
                    setVh(window.innerHeight * 0.01)
                }
            });
        }
        },
        [mySwiper]
    )

    return(
        <div style={{ background: background }}>
            {images ? <Swiper { ...params } getSwiper={initSwiper} className="swiper">
            { 
                images.map((image, i) => {
                    if(!image.mediaDetails) return false
                    const sizes = image.mediaDetails.sizes
                    if(!sizes) return false
                    const aspectRatio = sizes[sizes.length-1].width/sizes[sizes.length-1].height
                    const height = `${vh*60}px`;
                    const width = `${vh*60*aspectRatio}px`

                    let sources = []
                    sizes.forEach((size) => {
                        sources.push(`${size.sourceUrl} ${size.width}w`)
                    })

                    const srcset = sources.join(', ')

                    return <div key={i} className="swiper-slide" style={{height, width}}><img 
                                loading="lazy" 
                                data-sizes="auto"
                                // src={base64}
                                data-srcset={srcset}
                                alt={image.source_url}
                                className="swiper-lazy"
                                style={{height, width, position: "relative"}}
                            />
                        <LazySizes image={image} />
                    </div>
                })
            }
            </Swiper> : false}
            <style>
                {`
                    .swiper-slide{
                        overflow: hidden;
                    }
                    .swiper-lazy{
                        transition: filter 0.3s;
                        filter: blur(4vh)
                    }
                    .swiper-lazy-loaded{
                        filter: blur(0)
                    }
                    .swiper-slide-active div{
                        transition: opacity .3s;
                        opacity: 1;
                    }
                `}
            </style>
        </div>
    )
}

export default Slideshow