import React from "react"
// import Pattern from '../layout/pattern'
// import SectionLinks from './sectionLinks'
import './header.scss'

// import ScrollAnimation from 'react-animate-on-scroll';

export default ({ title, list, sections}) => (
  <div className="header">
    <div className="headerWrapper">
      <div >
        <div dangerouslySetInnerHTML={{__html: title}}/>
      </div> 
      <div>
      
        {list.length > 1 && list[0].label !== "" ? <div className="list" draggable="true">
          <div className="listWrapper">
            {
              list ? list.map((item, i) => (
                <div key={i}>
                  <div>{item.label}</div>
                  <div> {item.text}</div>
                </div>
              )) : false
            }
          </div>
        </div> : false}
        { sections.map((section, i) => {
          if(section.__typename !== "WPGraphQL_Post_Project_Sections_Text") return false
            return(
              <div key={i} className="listText" dangerouslySetInnerHTML={{__html: section.text}} />
            )
          })
        }
      </div>
    </div>
  </div>
)
