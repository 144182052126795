import React from "react"

import './video.scss'

const Video = ({ video, className}) => {

  if(!video) return false;

  return(
    <div className={className}>
      <div className="video-post" >
        <video src={video.mediaItemUrl} autoPlay={true} loop={true} muted={true} />
      </div>
    </div>
  )
}


export default Video