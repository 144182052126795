import React from 'react'
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/respimg/ls.respimg.js';


import './lazysizes.scss'

// const LazySizes = ({ image, preload, className, style }) => {

export default ({ image, preload, style, factor, postTitle }) => {

    const sizes = image.mediaDetails.sizes.sort(function(a, b) {
        return a.width - b.width;
    })
    if(!sizes){
        console.log('IMAGE THUMBNAILS ARE MISSING') 
        return null
    }

    const alt = image.altText || postTitle || ""

    let sources = []


    const devicePixelRatio = 2
    // const isRetina = 

    sizes.forEach((size) => {
        // if(size.width === "400") return false
        let width = size.width
        if(factor) width = size.width*factor
        sources.push(`${size.sourceUrl} ${width/devicePixelRatio}w`) 
    })
    // console.log(image)
    // sources.push(`${image.mediaItemUrl} `) 
    
    // if(image.sourceUrl) sources.push(image.sourceUrl)
    if(image.mediaItemUrl) sources.push(`${image.mediaItemUrl} 2500w`)

    const srcset = sources.join(', ')

    return (
        <div className="image__container__center" style={{ ...style }}>
            <div className="image__wrapper">
                <img 
                data-sizes="auto"
                data-srcset={srcset}
                alt={alt}
                className={`lazyload`}
                />
            </div>
        </div>
    )
}
