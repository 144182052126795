import React from "react"
// import ScrollAnimation from 'react-animate-on-scroll';

import './images.scss'
import LazySizes from '../image/lazysizes'
import Masonry from 'react-masonry-css'
 
const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  700: 1,
};

export default ({ images, className}) => {

  let background = { overflow: "hidden", position: "relative" }

  return(
    <div style={background} className={className}>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {
          images.map((image, i) => (
            <div key={i} className="masonry-element" >
              <LazySizes image={image} preload={true} />
            </div>
          ))
        }
      </Masonry>
    </div>
  )
}
